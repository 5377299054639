import { types, flow, getRoot } from "mobx-state-tree";
import axios from "axios";
import { Frames } from "frames-react";
import {
  axiosError,
  BACKEND_URL,
  SEARCH_TYPES,
  visitorInfo,
  validate,
  STEPS,
  checkCookiesDisabled,
  savePersistedApplicationState, STATE_NAME_TO_ABBREVIATION
} from "../helpers";

import GoogleAnalytics from "../tracking/google";
import socketChicagoBot from "../sockets/socketChicagoBot";

const PaymentFormField = types.model("PaymentFormField", {
  value: types.optional(types.string, ""),
  error: types.optional(types.string, ""),
  dirty: types.optional(types.boolean, false)
});

const NotPaidTicket = types.model("NotPaidTicket", {
  ticketId: types.string,
  error: types.string,
})

const PaymentStore = types
  .model("PaymentStore", {
    files: types.maybeNull(types.frozen({})),
    showDropIn: false,
    enableSubmit: false, // allow form to submit
    paymentReady: false, // CKO dropin callback will flip this when it has fully loaded and is ready for user input
    isCardValid: false, // CKO dropin callback sets this accordingly as card/payment info is changed/updated
    clientToken: types.maybeNull(types.string), // needed for Braintree drop-in form
    result: types.maybeNull(types.frozen({})), // to show info for confirmation
    submitted: false, // has pay button been clicked
    acceptedTerms: false,
    hostingPageLink: types.maybeNull(types.string),
    chicagoBotConfirmationID: types.optional(types.string, ""),
    errorToast: types.optional(types.string, ""),
    chicagoBotError: types.optional(types.frozen({}), ""),
    chicagoBotNotPaidList: types.optional(types.array(NotPaidTicket), []),
    isChicagoBotRunning: types.optional(types.boolean, false),
    billingAddressLine1: types.optional(PaymentFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    billingAddressLine2: types.optional(PaymentFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    billingAddressZip: types.optional(PaymentFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    billingAddressCity: types.optional(PaymentFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    billingAddressState: types.optional(PaymentFormField, {
      value: "",
      error: "",
      dirty: false
    }),
    billingAddressCountry: types.optional(PaymentFormField, {
      value: "",
      error: "",
      dirty: false
    })
  })
  .actions(self => {
    return {
      loadPaymentState(state) {
        for (let key of state) {
          self[key] = state[key];
        }
      },

      getCkoPaymentIdFromSessionId(sessionId) {
        return axios
          .get(
            `${BACKEND_URL}/initiatePayment/ckoPaymentIdFromSessionId/${sessionId}`
          )
          .then(response => response.data.ckoPaymentId);
      },

      setPaymentField(fieldName, fieldValue) {
        self.setPaymentFieldValue(fieldName, fieldValue);
        self.onChangePaymentFieldValidation(fieldName, fieldValue);
        return self.checkIfCanSubmit();
      },

      onChangePaymentFieldValidation(fieldName, fieldValue) {
        const field = self[fieldName];
        switch (fieldName) {
          case "billingAddressLine1":
            if (field.dirty) {
              if (!fieldValue.length) {
                self.setPaymentFieldError(
                  fieldName,
                  "Billing address is a required field."
                );
              } else {
                self.setPaymentFieldError(fieldName, "");
              }
            }
            break;
          case "billingAddressCity":
            if (field.dirty) {
              if (!fieldValue.length) {
                self.setPaymentFieldError(
                  fieldName,
                  "City is a required field."
                )
              } else {
                self.setPaymentFieldError(fieldName, "");
              }
            }
            break;
          case "billingAddressZip":
            if (field.dirty) {
              const isValidZipCode = validate.zipCode(fieldValue);
              if (!fieldValue.length) {
                self.setPaymentFieldError(
                  fieldName,
                  "Zip code is a required field."
                );
              } else if (!isValidZipCode) {
                self.setPaymentFieldError(fieldName, "Invalid zip code.");
              } else {
                self.setPaymentFieldError(fieldName, "");
              }
            }
            break;
          default:
            break;
        }
        return self.checkIfCanSubmit();
      },

      onBlurPaymentFieldValidation() {
        self.checkIfCanSubmit();
      },

      getFailureMessage(ckoSessionId) {
        const { general } = getRoot(self);
        general.clearError();

        axios
          .post(
            `${BACKEND_URL}/checkoutFailure`,
            { ckoSessionId },
            { withCredentials: true }
          )
          .then(res => {
            return general.setError(res.data.error);
          })
          .catch(err => {
            console.error(err);
          });
      },

      queueChicagoPayment (chicagoSessionId, useTimeout = false) {
        let data = {
          chicagoSessionId
        }
        if (useTimeout) {
          data.chicagoBotTimeout = process.env.REACT_APP_CHICAGO_BOT_TIMEOUT * 1000
        }
        return axios
          .put(`${BACKEND_URL}/evervault/queueChicagoPayment`, data,{ withCredentials: true })
      },

      preAuthorizeChargeWithEv: flow(function*(params) {
        const { violation, person } = getRoot(self)
        const violationNumbers = Object.keys(violation.selected);
        const selectedTicketsIds = violationNumbers.map(
          key => violation.selected[key].violationNumber
        );
        const licensePlate = violationNumbers.map(
          key => violation.selected[key].licensePlate
        )[0]
        return yield axios
          .post(`${BACKEND_URL}/evervault/outboundPreAuthorize`,{ // Calling new backend route which will get a link for 3ds
            visitorInfo: visitorInfo(),
            person: {
              firstName: person.firstName.value,
              lastName: person.lastName.value,
              phoneNumber: person.phoneNumber.value,
              email: person.email.value,
            },
            billingAddressLine1 :  self.billingAddressLine1.value,
            billingAddressCity : self.billingAddressCity.value,
            billingAddressState : STATE_NAME_TO_ABBREVIATION[self.billingAddressState.value],
            billingAddressZip: self.billingAddressZip.value,
            billingAddressCountry: self.billingAddressCountry.value,
            selectedTicketsIds: selectedTicketsIds,
            plateNumber: licensePlate,
            cardNumberAlias: params.cardNumberAlias,
            cardExpDate: params.cardExp,
            cardCvcAlias: params.cardCvcAlias,
            cardBrand: params.cardBrand,
            city: {
              id: 'Chicago, Illinois',
              name: 'Chicago',
              state: 'Illinois',
              shortState: 'IL',
            },
          }, { withCredentials: true })
      }),

      payCityBot: flow(function*(params) {
        let timeoutId
        const { violation, person } = getRoot(self)
        const violationNumbers = Object.keys(violation.selected)
        const ticketDetails = violationNumbers.map(key => {
          return {
            ticketId: violation.selected[key].violationNumber,
            amount: violation.selected[key].amount
          }
        })
        try {
          socketChicagoBot.connect()
          const payData = {
            sessionID: params.paymentId,
            tickets: ticketDetails,
            firstName: person.firstName.value,
            lastName: person.lastName.value,
            email: person.email.value,
            phone: person.phoneNumber.value,
            address: self.billingAddressLine1.value,
            city: self.billingAddressCity.value,
            state: STATE_NAME_TO_ABBREVIATION[self.billingAddressState.value],
            zipCode: self.billingAddressZip.value,
            creditCard: params.cardNumberAlias,
            expMonth: params.cardExp.split("/")[0],
            expYear: '20' + params.cardExp.split("/")[1],
            cvv: params.cardCvcAlias,
            actuallyPay: process.env.NODE_ENV === "production",
          }
          socketChicagoBot.emit("pay_chicago", payData)
          yield self.queueChicagoPayment(params.paymentId, true)
          timeoutId = setTimeout(async () => {
            self.setErrorToast("Service unavailable")
            socketChicagoBot.disconnect()
            self.setIsChicagoBotRunning(false)
          }, process.env.REACT_APP_CHICAGO_BOT_TIMEOUT * 1000)
          socketChicagoBot.once("chicago_error", async (socketErrorResult) => {
            console.log('socketErrorResult', socketErrorResult)
            self.setIsChicagoBotRunning(false)
            self.setChicagoBotError(socketErrorResult.error)
            if (socketErrorResult.status === 'queued') {
              await self.queueChicagoPayment(socketErrorResult.sessionID)
              return
            }
            self.setSubmitted(false)
            await axios
              .post(`${BACKEND_URL}/evervault/outboundVoidFees`, { // Calling new backend route which will get a link for 3ds
                cityPaySessionId: socketErrorResult.sessionID,
                city: {
                  id: 'Chicago, Illinois',
                  name: 'Chicago',
                  state: 'Illinois',
                  shortState: 'IL',
                },
              }, {withCredentials: true})
            clearTimeout(timeoutId)
            socketChicagoBot.disconnect()
          })

          socketChicagoBot.once("chicago_paid", async (socketSuccessResult) => {
            console.log('socketSuccessResult', socketSuccessResult)
            const { general } = getRoot(self)
            self.setIsChicagoBotRunning(false)
            if (
              socketSuccessResult.notPaidList &&
              socketSuccessResult.notPaidList.length > 0
            ) {
              self.setChicagoBotNotPaidList(socketSuccessResult.notPaidList)
            }
            clearTimeout(timeoutId)
            socketChicagoBot.disconnect()
            self.setChicagoBotConfirmationID(socketSuccessResult.confirmationID)
            await axios
              .post(`${BACKEND_URL}/evervault/outboundChargeFees`, { // Calling new backend route which will get a link for 3ds
                cityPaySessionId: socketSuccessResult.sessionID,
                ticketIdsNotPaid: socketSuccessResult.notPaidList || [],
                city: {
                  id: 'Chicago, Illinois',
                  name: 'Chicago',
                  state: 'Illinois',
                  shortState: 'IL',
                },
              }, {withCredentials: true})
            return general.setStep(STEPS.done)

          })
        } catch (error) {
          clearTimeout(timeoutId)
          socketChicagoBot.disconnect()
          self.setErrorToast("Service unavailable")
          self.setIsChicagoBotRunning(false)
        }
      }),
      savePaymentDetails: flow(function*(ckoSessionId) {
        const { violation, payment, general } = getRoot(self);
        const violationNumbers = Object.keys(violation.selected);
        const data = {
          ckoSessionId,
          uploadedImageID: payment.files?.id?.id ? [payment.files.id.id] : null,
          selectedTicketsIds: violationNumbers.map(
            key => violation.selected[key]._id
          ),
          city: general.location
        };

        try {
          yield axios.post(`${BACKEND_URL}/savePaymentDetails`, data);
          return true;
        } catch (error) {
          console.error("Error while saving payment details: ", error);
          return false;
        }
      }),

      submitOrder(token, cardScheme) {
        const { general, search, violation, person } = getRoot(self);
        general.clearError();
        let params = {};

        switch (search.searchType) {
          case SEARCH_TYPES.full: {
            params = {
              plateNumber: search.plateNumber,
              plateState: search.plateState,
              plateOwnerName: search.lastName
            };
            break;
          }
          case SEARCH_TYPES.ticket: {
            params = search.ticketId;
            break;
          }
          default:
            break;
        }
        // violation.selected is an object with violation numbers as keys
        const violationNumbers = Object.keys(violation.selected);
        const selectedTicketsIds = violationNumbers.map(
          key => violation.selected[key]._id
        );
        const licensePlate = violationNumbers.map(
          key => violation.selected[key].licensePlate
        )[0];
        if (process.env.REACT_APP_USE_BRAINTREE === "true")
          return axios
            .post(
              `${BACKEND_URL}/checkout/submit`,
              {
                params,
                visitorInfo: visitorInfo(),
                uploadedImageID: self.files?.id?.id ? [self.files.id.id] : null,
                person: {
                  firstName: person.firstName.value,
                  lastName: person.lastName.value,
                  phoneNumber: person.phoneNumber.value,
                  email: person.email.value
                },
                selectedTicketsIds,
                plateNumber: licensePlate,
                clientNonce: token,
                city: general.location
              },
              { withCredentials: true }
            )
            .then(res => {
              const { general } = getRoot(self);
              self.setPaymentResult(res.data);
              GoogleAnalytics.action.submitPayment(violation.orderTotal);
              return general.setStep(STEPS.done);
            })
            .catch(error => {
              self.setPaymentReady(false);
              self.setSubmitted(false);
              console.error("error in submit order", error); //eslint-disable-line
              const message = axiosError(error);
              try {
                GoogleAnalytics.action.submitPaymentError(
                  JSON.stringify(message)
                );
              } catch (err) {
                console.error(err);
              }
              return general.setError(message);
            });
        else
          return axios
            .post(
              `${BACKEND_URL}/initiatePayment`,
              {
                // Calling new backend route which will get a link for 3ds
                params,
                visitorInfo: visitorInfo(),
                uploadedImageID: self.files?.id?.id
                  ? [
                      self.files.id.id
                      // self.files.creditCard.id,
                    ]
                  : null,
                person: {
                  firstName: person.firstName.value,
                  lastName: person.lastName.value,
                  phoneNumber: person.phoneNumber.value,
                  email: person.email.value,
                  billingAddress: {
                    addressLine1: self.billingAddressLine1.value,
                    addressLine2: self.billingAddressLine2.value,
                    zip: self.billingAddressZip.value,
                    city: self.billingAddressCity.value,
                    state: self.billingAddressState.value,
                    country: self.billingAddressCountry.value
                  }
                },
                selectedTicketsIds,
                plateNumber: licensePlate,
                clientToken: token,
                cardScheme: cardScheme,
                city: general.location
              },
              { withCredentials: true }
            )
            .then(res => {
              // CKO api may or may not require a "challenge flow" for 3ds. If so, we need to be able to
              // leave our domain and come back after the user completes the 3ds challenge with persisted app state.
              if (res.data.error) return general.setError(res.data.error);
              const { redirectLink, ckoPaymentId } = res.data;
              if (!redirectLink) {
                savePersistedApplicationState(getRoot(self), ckoPaymentId).then(
                  () => {
                    self.savePaymentDetails(ckoPaymentId);
                    general.setStep(STEPS.done);
                  }
                );
              } else {
                return savePersistedApplicationState(
                  getRoot(self),
                  ckoPaymentId
                ).then(() => {
                  window.location.replace(redirectLink);
                });
              }
            })
            .catch(error => {
              Frames.init(); // Allow the CKO dropin to recover from the error and re-render
              self.setPaymentReady(false);
              self.setSubmitted(false);
              console.error("error in submit order", error); //eslint-disable-line
              const message = axiosError(error);
              try {
                GoogleAnalytics.action.submitPaymentError(
                  JSON.stringify(message)
                );
              } catch (err) {
                console.error(err);
              }
              return general.setError(message);
            });
      },

      requestClientToken() {
        const { general, search } = getRoot(self);
        checkCookiesDisabled().then(cookiesDisabled => {
          if (cookiesDisabled) {
            search.setFetchingStatus(false);
            return general.setError({
              title: "Cookies are required!",
              message: "Please enable cookies in your web browser."
            });
          }
          return axios
            .get(`${BACKEND_URL}/checkout/clientToken`, {
              withCredentials: true
            })
            .then(res => {
              self.setClientToken(res.data);
            })
            .catch(error => {
              console.error("error in request client token", error); //eslint-disable-line
              const message = axiosError(error);
              return general.setError(message);
            });
        });
      },

      uploadImage(image, key) {
        const { general, search } = getRoot(self);
        general.clearError();
        search.setFetchingStatus(true);
        const extension = image.type.split("/")[1];
        return axios
          .post(`${BACKEND_URL}/checkout/imageUpload/${extension}`, image, {
            withCredentials: true,
            headers: {
              "Content-Type": image.type
            }
          })
          .then(res => {
            search.setFetchingStatus(false);
            self.setUploadedImageId(res.data.imageId, key);
          })
          .catch(error => {
            search.setFetchingStatus(false);
            console.error("error uploading user image", error); //eslint-disable-line
            const message = axiosError(error);
            return general.setError(message);
          });
      },

      clickAcceptedTerms() {
        self.toggleAcceptedTerms();
        return self.checkIfCanSubmit();
      },

      paymentReadyStatusChange(status) {
        self.setPaymentReady(status);
        return self.checkIfCanSubmit();
      },

      cardValidityStatusChange(bool) {
        self.setIsCardValid(bool);
        return self.checkIfCanSubmit();
      },

      checkIfCanSubmit() {
        const { payment, person, violation } = getRoot(self);
        if (
          payment.acceptedTerms &&
          payment.paymentReady &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : payment.isCardValid) &&
          !person.firstName.error &&
          person.firstName.dirty &&
          !person.lastName.error &&
          person.lastName.dirty &&
          !person.phoneNumber.error &&
          person.phoneNumber.dirty &&
          !person.email.error &&
          person.email.dirty &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : payment.billingAddressLine1.dirty) &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : !payment.billingAddressLine1.error) &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : !payment.billingAddressLine2.error) && // Address line 2 is optional so just check for error
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : payment.billingAddressCity.dirty) &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : !payment.billingAddressCity.error) &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : payment.billingAddressState.value) &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : payment.billingAddressZip.dirty) &&
          (process.env.REACT_APP_USE_BRAINTREE === "true"
            ? true
            : !payment.billingAddressZip.error)
        ) {
          // Check if we need additional info
          const ticketCount = Object.keys(violation.selected);
          if (
            (violation.totalSelectedFines >= 500 || ticketCount.length > 2) &&
            !payment.files.id.id
          ) {
            return self.setEnableSubmit(false);
          }
          return self.setEnableSubmit(true);
        }
        return self.setEnableSubmit(false);
      },

      setClientToken(token) {
        self.clientToken = token;
      },

      resetPayment() {
        self.files = {
          id: {
            file: "",
            imagePreviewUrl: "",
            id: ""
          },
          creditCard: {
            file: "",
            imagePreviewUrl: "",
            id: ""
          }
        };
        self.showDropIn = false;
        self.enableSubmit = false;
        self.paymentReady = false;
        self.isCardValid = false;
        self.clientToken = null;
        self.result = null;
        self.submitted = false;
        self.acceptedTerms = false;
        self.hostingPageLink = null;
        self.billingAddressLine1 = {
          value: "",
          error: "",
          dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
        };
        self.billingAddressLine2 = {
          value: "",
          error: "",
          dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
        };
        self.billingAddressZip = {
          value: "",
          error: "",
          dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
        };
        self.billingAddressCity = {
          value: "",
          error: "",
          dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
        };
        // State & Country can likely be refactored to primitive fields -> Input data is controlled via dropdowns
        self.billingAddressState = {
          value: "",
          error: "",
          dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
        };
        self.billingAddressCountry = {
          value: "United States",
          error: "",
          dirty: process.env.REACT_APP_USE_BRAINTREE === "false"
        };
      },

      setDropIn(showBool) {
        self.showDropIn = showBool;
      },

      setPaymentReady(readyBool) {
        self.paymentReady = readyBool;
      },

      setIsCardValid(validBool) {
        self.isCardValid = validBool;
      },

      setEnableSubmit(readyBool) {
        self.enableSubmit = readyBool;
      },

      setPaymentResult(result) {
        self.result = result;
      },

      setSubmitted(readyBool) {
        self.submitted = readyBool;
      },

      toggleAcceptedTerms() {
        self.acceptedTerms = !self.acceptedTerms;
      },

      setHostingPageLink(result) {
        self.hostingPageLink = result;
      },

      setUploadedImageId(id, key) {
        const files = { ...self.files };
        files[key] = {
          ...files[key],
          id: id
        };
        self.files = files;
      },

      setFile(key, file, imagePreviewUrl) {
        const files = { ...self.files };
        files[key] = {
          file,
          imagePreviewUrl
        };

        self.files = files;
      },

      setPaymentFieldValue(fieldName, fieldValue) {
        self[fieldName].value = fieldValue;
      },

      setPaymentFieldDirty(fieldName) {
        self[fieldName].dirty = true;
      },

      setPaymentFieldError(fieldName, fieldError) {
        self[fieldName].error = fieldError;
      },

      setIsChicagoBotRunning(value) {
        self.isChicagoBotRunning = value
      },

      setChicagoBotConfirmationID(value) {
        self.chicagoBotConfirmationID = value
      },

      setChicagoBotError(value)  {
        self.chicagoBotError= value
      },

      clearChicagoBotError() {
        self.chicagoBotError = ""
      },

      setErrorToast(value)  {
        self.errorToast= value
      },

      clearErrorToast() {
        self.errorToast = ""
      },

      setChicagoBotNotPaidList(value)  {
        self.chicagoBotNotPaidList= value
      }
    };
  });

export default PaymentStore;
