import React from "react";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import FullInput from "../forms/fullInput";

const BillingAddressCity = () => {
  const { payment } = useStore();

  const handleChange = event => {
    payment.setPaymentField("billingAddressCity", event.target.value);
  };

  const handleBlur = event => {
    payment.setPaymentFieldDirty(event.target.name);
    payment.onBlurPaymentFieldValidation(event.target.name);
  };

  return (
    <FullInput
      title="City"
      placeholder="City"
      name="billingAddressCity"
      handleChange={handleChange}
      handleBlur={handleBlur}
      value={payment.billingAddressCity.value}
      dirty={payment.billingAddressCity.dirty}
      error={payment.billingAddressCity.error}
      type="text"
      className="uppercase"
    />
  );
};

export default observer(BillingAddressCity);
