export const email = emailInput => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(emailInput).toLowerCase());
};

export const phoneNumber = phoneNumberInput => {
  const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return phoneNumberPattern.test(phoneNumberInput);
};

export const isNumeric = string => !isNaN(string); //eslint-disable-line

export const zipCode = string => {
  if (string.length < 5) return false;
  const zipCodePattern = /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/; // validate US and Canada but we might should pass the country for the check
  return zipCodePattern.test(string);
}
